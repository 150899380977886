import * as React from "react";

import Puzzle from "../../Puzzle";

import romantycznaCeramika from "../../images/romantyczna-ceramika.jpg";
import map from "../../images/m5.png";
// import audio from "../../../static/a5.mp3";

const Page = () => {
  return (
    <Puzzle
      title="Riddle 5"
      author="Author: Mika Drozdowska"
      imageSrc={romantycznaCeramika}
      imageAlt="ceramiczne głowy na elewacji"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      // audio={audio}
      language="en"
      languageUrl={"/zagadki/4cjRSPUoRj"}
    >
      <div>
        <p>
          Everyone who looks at it knows that the Renoma department store, once
          called Wertheim, is an example of the greatest architecture in
          Wrocław.
        </p>
        <p>
          {" "}
          Designed by Hermann Dernburg, opened on April 2, 1930, it is
          undoubtedly the most characteristic and dominant building of the
          Kościuszko Square. The six-storey building erected on a steel
          structure – the largest frame building in Europe – was a symbol of the
          modernity and progressiveness of the city. The sales halls, elevators,
          the first escalator in Silesia and two four-storey courtyards covered
          with glass skylights and inlaid with African wood made a huge
          impression.
        </p>
        <p>
          Looking at its dignified exterior, we have the right impression that
          the entire volume is shimmering reflecting the lights of both day and
          night. This is due to the carefully thought-out facade constructed
          from the strips of windows running around the entire building and the
          ceramic cornices between them as well as the sill walls faced with
          glazed ceramics with an admixture of gold flakes. It is these two
          aspects that have contributed to the fact that this ethos building is
          still part of Wrocław. On March 12, 1945, during the siege of Breslau,
          it was bombed and stood in flames for several days. The fortunate
          physical properties of steel and clinker, materials resistant to high
          temperatures, allowed him to survive.
        </p>
        <p>
          The facade details in the form of 104 sculptures of human heads and
          flowers were also made of ceramics, to which great importance was
          attached. Not all of them survived the bombing. The destroyed ones
          were restored and reconstructed (72 replicas) in the years 2007-2009
          by Paulina Pokorny-Ziemba. Their heads, and especially one of them,
          require a closer look worth discovering their non-obvious romantic
          story. They are the work of two sculptors Ulrich Nitschke and Hans
          Klakow. It was thanks to them that Wertheim was surrounded by images
          of people from all over the world. They were to symbolize the
          cosmopolitan nature of the city and the wide range of products hidden
          behind the walls of a department store. By lifting your head and
          looking at the exotic and diverse types that take us to distant
          corners of the world, our attention may be drawn to the face of a
          European woman in a characteristic Art Deco cap. Can you see her? She
          is the wife of Ulrich Nitsche, who – in a romantic gesture –
          immortalized the face of his beloved Hadwig on the facade. Thanks to a
          wonderful coincidence and the grandchildren of both artists who
          delivered the archival photos and the original model to Wrocław, there
          is no doubt about it. The sculpture of the head of Ulrich Nitschke's
          wife was created in 1929 and at the time it was made, Hadwig was 47
          years old. The years pass and the proof of the artist's love for his
          wife, in the form of a ceramic sculpture, is still with us and takes a
          worthy place in the urban space of Wrocław.
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
